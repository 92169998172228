import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {dataItemUpdate, fetchNewOrganization, fetchStats, fetchDirections, updateSettings} from "../../actions";
import SelectButton from "../form-controls/common/select-button";
import OperationsContainer from "./operations-container";
import {getServiceItemsApply} from "../../utils/utils";
import {toBrowserDate} from "../../utils/dates";

import ReactExport from "react-export-excel";
import Requests from "./requests/requests";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const REFRESH_INTERVAL = 300000;

const OperationsView = props => {

    const {fetchNewOrganization, fetchDirections, updateSettings, data, fetchStats, stats, directions} = props

    const [servicesData, setServicesData] = useState(getServiceItemsApply(data['services']));
    const [serviceItem, setServiceItem] = useState(null);
    const [servicesApply, setServicesApply] = useState(getServiceItemsApply(data['services']));
    const [page, setPage] = useState(0);

    const today = new Date()
    const fromDate = today.setMonth(today.getMonth() - 1);
    const toDate = new Date();
    const [dateFrom, setDateFrom] = useState(toBrowserDate(fromDate))
    const [dateTo, setDateTo] = useState(toBrowserDate(toDate))

    const [secondaryCategory, setSecondaryCategory] = useState(data.secondaryCategory.length ? data.secondaryCategory[0] : '');
    const [shortLabel, setShortLabel] = useState(data.shortLabel ? data.shortLabel : '');

    useEffect(() => {
        fetchDirections();
        fetchStats(data._id, dateFrom, dateTo)
        let interval = setInterval(() => {
            fetchNewOrganization(data._id, true);
        }, REFRESH_INTERVAL);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setServicesApply(getServiceItemsApply(data['services']));
        setShortLabel(data.shortLabel ? data.shortLabel : '')
        setSecondaryCategory(data.secondaryCategory.length ? data.secondaryCategory[0] : '');
        if (!serviceItem) setServiceItem(getServiceItemsApply(data['services'])[0] || null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const updateSettingsHandler = () => {
        updateSettings({
            secondaryCategory: secondaryCategory ? [secondaryCategory] : [],
            shortLabel: shortLabel ? shortLabel.trim() : ''
        })
    }

    /*
    const services = getServiceItems(data["services"], []);

    if (!services || services.length === 0) {
        return <p className='mt-20'>Сервисов для работы оператора не создано</p>;
    }

    const filteredServices = []
    services.forEach(item => {
        const serviceChildren = item.children || [];
        serviceChildren.forEach(child => {
            if (child.value !== 'off') {
                if (child.serviceType === 'service_apply' && child.children && child.children.length > 0) {
                    filteredServices.push(item);
                }
            }
        })
    })



    const selectItemHandler = (index, item) => {
        setServicesData(index === 0 ? servicesApply : [item])
    }
*/

    const selectItemHandler = (index, item) => {
        setServicesData([item])
        setServiceItem(item)
    }

    //const item = currentItem || services[0]
    //const children = item.children;

    if (!servicesApply || !servicesData) return null;

    const selectedMenuStyle = {
        textDecoration: 'underline',
        color: '#32B9F1'
    }

    const {organizationOpenings, servicesRequests, urlsRequests} = stats;

    if (!directions || !directions.length) return null;

    const currentCategory = directions.find(item => item.value === data.mainCategory).label

    return (
        <div className="mt-20 mb-15">
            <div className='d-flex-end'>
                <a href='https://moygorod.by/manual.pdf' target='_blank' rel='noopener noreferrer'><button className='button-link'>Скачать инструкцию</button></a>
            </div>
            <div className='d-flex-wrap-ac pt-10 pb-10'>
                <p onClick={() => setPage(0)} className='fs-14 enabledClick' style={page === 0 ? {} : selectedMenuStyle}>Записи</p>
                {data.requestCity && <p onClick={() => setPage(1)} className='ml-20 fs-14 enabledClick' style={page === 1 ? {} : selectedMenuStyle}>Обращения</p>}
                <p onClick={() => setPage(2)} className='ml-20 fs-14 enabledClick' style={page === 2 ? {} : selectedMenuStyle}>Статистика</p>
                <p onClick={() => setPage(3)} className='ml-20 fs-14 enabledClick' style={page === 3 ? {} : selectedMenuStyle}>Настройка</p>
            </div>
            <div className='separator mt-10 mb-20'/>
            {page === 0 &&
            <div>
                <div>
                    <SelectButton elements={servicesApply} callBackSelectClick={selectItemHandler} operations={true}/>
                </div>
                <div>
                    {/*
                        <SchedulerView servicesData={servicesData}/>
                    */}

                    {serviceItem && serviceItem.children &&
                    serviceItem.children.map((item) => <OperationsContainer key={item.id} id={item.id}/>)
                    }
                </div>
            </div>
            }
            {page === 1 &&
                <Requests />
            }
            {page === 2 &&
            <div>
                <h4>Статистика по организации</h4>
                <div className='mt-20'>
                    <div>
                        <div>Дата от: <input className='w-200' type='date' value={dateFrom} onChange={(e) => setDateFrom(e.target.value)}/></div>
                        <div>Дата до: <input className='w-200 mt-10' type='date' value={dateTo} onChange={(e) => setDateTo(e.target.value)}/></div>
                    </div>
                    <button onClick={() => fetchStats(data._id, dateFrom, dateTo)} className='mt-10 button-blue'>Загрузить</button>
                </div>
                <div>
                    <p className='mt-30'>{'Открытий организации: ' + organizationOpenings.length}</p>
                    <p className='mt-30'>{'Записей на сервисы: ' + servicesRequests.length}</p>
                    <p className="mb-10">Скачать данные по сервисам:</p>
                    <ExcelFile>
                        <ExcelSheet data={servicesRequests} name="Services">
                            <ExcelColumn label="Дата и время" value="date"/>
                            <ExcelColumn label="Название организации" value="organizationMainLabel" />
                            <ExcelColumn label="Название сервиса" value="serviceHeadingValue" />
                            <ExcelColumn label="Дата записи" value="serviceDate" />
                            <ExcelColumn label="Время записи" value="serviceTime" />
                            <ExcelColumn label="Имя пользователя" value="userName" />
                            <ExcelColumn label="Телефон" value="userPhone" />
                            <ExcelColumn label="Тип записи" value="serviceType" />
                        </ExcelSheet>
                    </ExcelFile>
                    <p className='mt-30'>{'Открытий ссылок из приложения: ' + urlsRequests.length}</p>
                    <p className="mt-20">Скачать данные по открытиям ссылок</p>
                    <ExcelFile>
                        <ExcelSheet data={urlsRequests} name="URLs">
                            <ExcelColumn label="Дата и время" value="date"/>
                            <ExcelColumn label="Название организации" value="organizationMainLabel" />
                            <ExcelColumn label="Ссылка" value="urlRequested" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
            </div>
            }
            {page === 3 &&
            <div>
                <h4>Настройка</h4>
                <div className='mt-30'>
                    <p className='mt-10 fs-14-12'>Для активных пользователей мессенджера Telegram теперь есть возможность получать уведомления о записях на сервисы через чат-бот <a href='https://t.me/moygorodby_bot' target='_blank' rel='noopener noreferrer'>@moygorodby_bot</a>. Введите свои имя пользователя и пароль и будьте в курсе всех записей в режиме реального времени!</p>
                    <div className='separator1px mt-20' />

                    <p className='mt-20 fs-14-12'>Ваше полное название организации</p>
                    <p className='mt-5 fs-14-12'><strong>{data.mainLabel}</strong></p>
                    <p className='mt-20 fs-14-12'>Вы можете указать короткое название для своей организации, которое будет видно в мобильном приложении. Если оставить это поле пустым, то в приложении будет видно полное название.</p>
                    <div className='mt-5'>
                        <input value={shortLabel} onChange={(e) => setShortLabel(e.target.value)} />
                    </div>
                    <div className='separator1px mt-20' />
                    <p className='mt-20 fs-14-12'>Основная категория</p>
                    <p className='mt-5'><strong>{currentCategory}</strong></p>
                    <p className='mt-20 fs-14-12'>Дополнительная категория</p>
                    <div className='mt-5'>
                        <select value={secondaryCategory} onChange={(e) => setSecondaryCategory(e.target.value)}>
                            {[{label: '', value: ''}, ...directions].map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                        </select>
                    </div>
                    <button onClick={() => updateSettingsHandler()} className='mt-10 button-blue'>Сохранить</button>
                </div>
            </div>
            }
        </div>
    )

}

const mapStateToProps = ({ user: { data, section, stats, directions }}) => {
    return { data, section, stats, directions };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch),
        fetchDirections: fetchDirections(dispatch),
        updateSettings: updateSettings(dispatch),
        fetchStats: fetchStats(dispatch),
        fetchNewOrganization: fetchNewOrganization(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationsView);
