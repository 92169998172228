import {exchangeArrayItems} from "../utils";

const updateDataById = (data, id, value, children, visible, recurrent) => {

    for (let i=0; i<data.length; i++) {

        if (data[i]._id === id || data[i].id === id) {

            if (value || value === "") data[i].value = value;
            if (children) data[i].children = children;
            if (visible) data[i].visible = visible;
            if (recurrent) data[i].recurrent_dates = recurrent;

            return;
        }

        if (data[i].children)
        {
            updateDataById(data[i].children, id, value, children, visible, recurrent)
        }

        if (data[i].recurrent_dates)
        {
            updateDataById(data[i].recurrent_dates, id, value, children, visible, recurrent)
        }
    }
}

const removeDataById = (data, id) => {

    for (let i=0; i<data.length; i++) {

        if (data[i]._id === id || data[i].id === id) {
            data.splice(i, 1)
            return;
        }

        if (data[i].children && data[i].children.length > 0)
        {
            removeDataById(data[i].children, id)
        }

        if (data[i].recurrent_dates && data[i].recurrent_dates.length > 0)
        {
            removeDataById(data[i].recurrent_dates, id)
        }
    }
}

const exchangeDataById = (data, id, index, direction) => {

    for (let i=0; i<data.length; i++) {

        if (data[i]._id === id || data[i].id === id) {
            exchangeArrayItems(data, index, direction);
            return
        }

        if (data[i].children)
        {
            exchangeDataById(data[i].children, id, index, direction)
        }
    }

}


const updateData = (state, action) => {

    if (state === undefined) {
        return {
            data: {},
            stats: {},
            requests: [],
            section: "",
            token: "",
            organizations: [],
            directions: [],
            loading: false,
            validation: {},
            saveNeeded: false,
            operationId: null,
            error: null,
            loaded: false,
        };
    }

    switch (action.type) {

        case 'FETCH_USER_REQUEST':
            return {
                ...state.user,
                loading: true
            };

        case 'FETCH_USER_SUCCESS': {

            console.log(action.payload)

            //sort news section
            if (action.payload.news && action.payload.news.length > 1) {
                action.payload.news.sort(function(a, b) {
                    const date1 = new Date(a.value.date);
                    const date2 = new Date(b.value.date);
                    return date2 - date1
                });
            }

            return {
                ...state.user,
                data: action.payload,
                section: (state.user.section === "") ? action.payload.sections[0].name : state.user.section,
                loading: false,
                saveNeeded: false,
                loaded: true,
            }
        }

        case 'FETCH_USER_FAILURE':
            return {
                ...state.user,
                error: action.payload,
            };

        case 'UPDATE_SECTION':
            return {
                ...state.user,
                section: action.payload,
            };

        case 'UPDATE_DIRECTIONS':
            return {
                ...state.user,
                directions: action.payload,
            };

        case 'UPDATE_REQUESTS':
            return {
                ...state.user,
                requests: action.payload,
            };

        case 'UPDATE_VALIDATION': {

            const {form, id, value} = action.payload;

            const validation = {...state.user.validation};

            if (!validation[form]) validation[form] = {}
            validation[form][id] = value;

            return {
                ...state.user,
                validation: validation
            };
        }

        case 'UPDATE_USER_TOKEN':
            return {
                ...state.user,
                token: action.payload,
            };

        case 'UPDATE_STATS':
            return {
                ...state.user,
                stats: action.payload,
            };

        case 'UPDATE_ORGANIZATIONS':
            return {
                ...state.user,
                organizations: action.payload,
            };

        case 'LOG_OUT':
            return {
                ...state.user,
                data: {},
                section: "",
                token: "",
                organizations: [],
                loading: false,
                saveNeeded: false,
                operationId: null,
                loaded: false,
                error: null
            };

        case 'DATA_ORDER_UPDATE': {

            const id = action.payload.id;
            const index = action.payload.index;
            const direction = action.payload.direction;
            const newData = {...state.user.data};

            exchangeDataById(newData[state.user.section], id, index, direction)

            return {
                ...state.user,
                data: newData,
                saveNeeded: true,
            }
        }

        case 'DATA_ITEM_UPDATE': {

            const id = action.payload.id;
            const value = action.payload.value;
            const children = action.payload.children;
            const visible = action.payload.visible;
            const recurrent = action.payload.recurrent;
            const actionToDo = action.payload.action;

            const section = (state.user.section !== "operations") ? state.user.section : "services";

            const newData = {...state.user.data};

            switch (actionToDo) {

                case 'delete': {

                    removeDataById(newData[section], id)
                    break;
                }

                case 'update': {

                    if (value || value === "" || children || visible || recurrent) {
                        updateDataById(newData[section], id, value, children, visible, recurrent)
                    }
                    break;
                }

                case 'update_root': {
                    if (value) {
                        newData[section].push(value)
                    }
                    break;
                }

                default: break;
            }

            return {
                ...state.user,
                data: newData,
                saveNeeded: true,
            }
        }

        default:
            return state.user;
    }
};

export default updateData;
