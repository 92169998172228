import React, { useState} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import FormMap from "./form-map";
import {InputLabeled} from "../index";

const InputLabeledAddress = props => {

    const {data, section, id, dataItemUpdate} = props;

    const [addressForMap, setAddressForMap] = useState(getItemById(data[section], id).value.textValue)


    const onChangeHandler = (value) => {

        const dataItem =  getItemById(data[section], id);

        dataItemUpdate({
            id: id,
            value: {
                lat: dataItem.value.lat || "",
                lng: dataItem.value.lng || "",
                textValue: value
            },
            action: "update"
        })
    }

    const onLatLng = (lat,lng) => {

        const dataItem =  getItemById(data[section], id);

        dataItemUpdate({
            id: id,
            value: {
                lat: Number(lat),
                lng: Number(lng),
                textValue: dataItem.value.textValue
            },
            action: "update"
        })
    }

    const onFocusoutHandler = () => {

        const dataItem =  getItemById(data[section], id);
        setAddressForMap(dataItem.value.textValue)
    }

    const dataItem =  getItemById(data[section], id);

    return (
        <div className="form-group">

            {/*
            <label>{dataItem.label}</label>
            <input type="text" onChange={this.onChangeHandler} onBlur={this.onFocusoutHandler} value={dataItem.value.textValue}/>
            */}
            <p className='mb-10 fs-14-12'>{dataItem.label}</p>
            <div className='d-flex wrapMobile'>
                <div className='w-290 mrweb-20'>
                    <FormMap address={addressForMap} onLatLng={onLatLng} />
                </div>
                <div className='wp-100'>
                    <InputLabeled textarea mandatory onBlur={onFocusoutHandler} onChange={onChangeHandler} initValue={dataItem.value.textValue} />
                </div>
            </div>


        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputLabeledAddress);
