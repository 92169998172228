import React from 'react';
import FormItem from "./form-item";
import {connect} from "react-redux";
import {getChildrenById} from "../../utils";
import {dataItemUpdate} from "../../actions";

const Form = props => {

    const { id, data, section, level } = props

    const dataArray = id ? getChildrenById(data[section], id) : data[section];
    const levelValue = level || 0;

    return (
        <div>
            {
                dataArray.map(
                    (item, index) => {

                        return (
                            <FormItem
                                key={ item._id }
                                level={ levelValue }
                                id={ item._id }
                                index={ index }
                                total={ dataArray.length - 1 }
                                data={ item }>

                            </FormItem>
                        )
                    }
                )
            }
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }}) => {
    return { data, section };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
