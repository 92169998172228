
const updateLanguage = (state, action) => {

    if (state === undefined) {
        return {
             texts: {
                 screens: {
                     loginScreen: {
                         loginText: 'Введите имя пользователя',
                         passText: 'Введите пароль',
                         saveMeText: 'Сохранить настройки входа',
                         loginNote: 'Вы можете получить имя пользователя в РУП Белтелеком',
                     },
                     adminPage: {
                         headingText: 'Мой город',
                         subheadingText: 'Система администрирования учреждений',
                         exitText: 'Выход',
                         previewText: 'Предварительный просмотр',
                         supportText: 'Техническая поддержка',
                         phonesText: {
                             polotsk: ['8(0214)-74-00-00', '8(0214)-46-55-99'],
                             orsha: ['8(0216)54-99-96', '8(0216)54-00-10'],
                             glubokoe: ['8(0216)54-99-96'],
                             pinsk: ['8(0165)65-66-70'],
                             narovlya: ['8(0235)54-42-99'],
                             lepel: ['8(0213)23-75-03'],
                         },
                     },
                 },
                 components: {
                     imageComponent: {
                         placeholderText: 'Изображение отсутствует',
                         descriptionText: 'Для оптимальной скорости загрузки ваших изображений в мобильном приложении, мы рекомендуем не использовать изображения больше, чем 640px по ширине',
                         loadingText: 'Загрузка изображения',
                         urlText: 'Изображение по ссылке',
                     },
                     saveComponent: {
                         saveText: 'Сохранить',
                     },
                     headingBlockComponent: {
                         headingText: 'Заголовок',
                         contentText: 'Текстовый блок',
                     },
                     serviceDirectoryComponent: {
                         directoryHeadingText: 'Название категории услуг и сервисов',
                     },
                     newsComponent: {
                         newsHeadingText: 'Новость',
                         newsTitleText: 'Заголовок новости',
                         newsDateText: 'Дата публикации',
                         newsTimeText: 'Время публикации',
                         newsMainText: 'Событие',
                         pollMainText: 'Опрос',
                         newsOffersText: 'Акция',
                         newsSpecialText: 'Главная',
                         newsDeleteText: 'Удаление новости',
                         newsExpDateText: 'Дата удаления',
                         newsExpTimeText: 'Время удаления',
                         newsMainDateText: 'Дата события',
                         newsContentText: 'Текст новости',
                         newsImageText: 'Изображение к новости',
                         newsUrlText: 'Ссылка на веб-сайт',
                         newsPreviewHeadingText: 'Редактировать заголовок новости',
                         newsPreviewContentText: 'Редактировать текст новости',
                     },
                     serviceComponent: {
                         serviceHeadingText: 'Сервис',
                         serviceTitleText: 'Название услуги',
                         serviceContentText: 'Описание услуги',
                         servicePriceText: 'Цена услуги, руб',
                         serviceImageText: 'Изображение услуги',
                         serviceUrlText: 'Ссылка на веб-сайт',
                         serviceEmailText: 'Email для уведомлений о записи',
                     },
                     formItemMenu: {
                         hideText: 'Скрыть',
                         showText: 'Показать',
                         deleteText: 'Удалить',
                         upText: 'Выше ',
                         downText: 'Ниже',
                     },
                 },
                 validationErrors: {
                     emailText: 'Вы ввели неверный email',
                     urlText: 'Вы ввели неверный адрес',
                     emptyText: 'Поле ввода не должно быть пустым',
                     minText: 'Текст в поле ввода слишком короткий',
                     maxText: 'Текст в поле ввода слишком длинный',
                 },
                 resErrors: {
                     res401: 'Произошла ошибка авторизации. Проверьте правильность имени пользователя и пароля. При необходимости, зайдите в систему заново.',
                     res403: 'Вы не можетете получить доступ к запрашиваемой информации. Проверьте правильность имени пользователя и пароля. При необходимости, зайдите в систему заново.',
                     res404: 'Запрашиваемая информация не найдена. Проверьте правильность имени пользователя и пароля. При необходимости, зайдите в систему заново.',
                     res400: 'Произошла ошибка в работе системы. Проверьте, что вы подключены к интернету. Если с соединением все в порядке, сообщите разработчикам об ошибке, написав на email: moygoroddev@gmail.com',
                 },
            },
        };
    }

    switch (action.type) {

        case 'FETCH_LNG_SUCCESS': {

            return {
                ...state.lng,
                texts: action.payload,

            }
        }

        default:
            return state.language;
    }
};

export default updateLanguage;
