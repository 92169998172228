import APIService from "../services/api-service";

const userRequested = () => {
    return {
        type: 'FETCH_USER_REQUEST'
    }
};


const userLoaded = (data) => {
    return {
        type: 'FETCH_USER_SUCCESS',
        payload: data
    };
};

const statsLoaded = (data) => {
    return {
        type: 'UPDATE_STATS',
        payload: data
    };
};

const userError = (error) => {
    return {
        type: 'FETCH_USER_FAILURE',
        payload: error
    };
};

const updateDirections = (data) => {
    return {
        type: 'UPDATE_DIRECTIONS',
        payload: data
    };
};

const updateRequests = (data) => {
    return {
        type: 'UPDATE_REQUESTS',
        payload: data
    };
};

const dataItemUpdate = (dispatch) => (data) => {

    if (data.action === "add") {

        const apiService = new APIService();
        apiService.createNewDataItem(data)
            .then(res => {
                dispatch(userLoaded(res));
            })
            .catch(error => {
                dispatch(userError(error));
            })
    } else {
        dispatch({
            type: 'DATA_ITEM_UPDATE',
            payload: data
        });
    }

    if (data.saveneeded) {
        saveAllData(dispatch)()
    }
};

const validationUpdate = (dispatch) => (data) => {

    dispatch({
        type: 'UPDATE_VALIDATION',
        payload: data
    });

};

const errorReset = (dispatch) => () => {
    dispatch(userError(null));
};

const saveAllData = (dispatch) => () => {

    const apiService = new APIService();
    apiService.saveAllData()
        .then(res => {
            dispatch(userLoaded(res));
        })
        .catch(error => {
            dispatch(userError(error));
        })

};


const dataOrderUpdate = (dispatch) => (data) => {
    dispatch({
        type: 'DATA_ORDER_UPDATE',
        payload: data
    });
};

const menuUpdate = (dispatch) => (data) => {
    dispatch({
        type: 'UPDATE_MENU',
        payload: data
    });
};

const logOut = (dispatch) => () => {
    dispatch({
        type: 'LOG_OUT'
    });
};

const menuVisibiltyUpdate = (dispatch) => (data) => {
    dispatch({
        type: 'UPDATE_MENU_VISIBILITY',
        payload: data
    });
};

const updateSection = (dispatch) => (data) => {
    dispatch({
        type: 'UPDATE_SECTION',
        payload: data
    });
};

const updateToken = (token) => {
    return {
        type: 'UPDATE_USER_TOKEN',
        payload: token
    };
};

const updateOrganizations = (organizations) => {
    return {
        type: 'UPDATE_ORGANIZATIONS',
        payload: organizations
    };
};


const fetchData = (userData, dispatch) => {

    const apiService = new APIService();

    if (userData && userData.token) {
        dispatch(updateToken(userData.token));
    } else {
        dispatch(userError(userData));
        return;
    }

    let organizationId = userData.organizationId

    if (Array.isArray(userData.organizationId)) {

        const tmpmapping = {
            "5f575ad95639aa469c83ac09" : "Тестовая организация 1",
            "5fc6b2e19e0c2eb5e7f1b3b1" : "Тестовая организация 2",
            "5f579cef7c45b550b060e2d7" : "Полоцкий районный исполнительный комитет",
            "5fb3b30376b652c69e2525d2" : "Oтдел идеологической работы и по делам молодежи",
            "5f57a52f7c45b550b060e30a" : "Кафе «Гурман», бар «Вест», кафе «С легким паром»",
            "5f57a37c7c45b550b060e2fe" : "Магазин «Юбилейный», магазин «Алиса»",
            "5f57a3357c45b550b060e2fc" : "ОАО «Полоцк-торг»",
            "5f57a5127c45b550b060e309" : "Кафе «Спатканне», «Чабор», «Былина, заккусочная «Кулинар», закусочная «Горячие блинчики»",
            "5f57a4eb7c45b550b060e308" : "Сеть магазинов «Дионис»",
            "5f57a3137c45b550b060e2fb" : "Кафе «Песто»",
            "5fb3b88876b652c69e2525dd" : "Управление экономики",
            "5fcd2fe92a81966ecab9a7eb" : "Продажа, аренда коммунальной недвижимости",
        }

        const arr = [];
        userData.organizationId.map((item) => arr.push({id:item, value:{headingValue:tmpmapping[item]}}))

        dispatch(updateOrganizations(arr));

        organizationId = userData.organizationId[0]
    }

    apiService.getAdminData(organizationId)
        .then(res => {
            dispatch(userLoaded(res));
        })
        .catch(error => {
            dispatch(userError(error));
        })

}

const fetchUser = (dispatch) => (loginCreds) => {

    const apiService = new APIService();

    dispatch(userRequested());

    apiService.getUserData(loginCreds)
        .then(res => {
            fetchData(res, dispatch)
        })
        .catch(error => {
            dispatch(userError(error));
        })
};

const fetchDirections = (dispatch) => () => {

    const apiService = new APIService();

    apiService.getCategories()
        .then(res => {
            dispatch(updateDirections(res));
        })
        .catch(error => {
            console.log(error)
        })
};

const updateSettings = (dispatch) => (settings) => {

    const apiService = new APIService();

    apiService.saveOrganizationSettings(settings)
        .then(res => {
            //dispatch(updateOrganizations(res));
            //saveAllData(dispatch)()
            if (res && res.length) dispatch(userLoaded(res[0]));
        })
        .catch(error => {
            console.log(error)
        })
};

const fetchModerator = (dispatch) => (login, password) => {

    const apiService = new APIService();

    dispatch(userRequested());

    apiService.getModeratorData(login, password)
        .then(data => {
            dispatch(userLoaded(data));
        })
        .catch(error => {
            dispatch(userError(error));
        })
};

const fetchNewOrganization = (dispatch) => (orgId, noLog) => {

    const apiService = new APIService();

    apiService.getAdminData(orgId, noLog)
        .then(res => {
            dispatch(userLoaded(res));
        })
        .catch(error => {
            dispatch(userError(error));
        })
};

const fetchStats = (dispatch) => (orgId, from, to) => {

    const apiService = new APIService();

    apiService.getStats(orgId, from, to)
        .then(res => {
            dispatch(statsLoaded(res));
        })
        .catch(error => {
            console.log(error)
        })
};

export const fetchRequests = (dispatch) => () => {

    const apiService = new APIService();

    apiService.getRequests()
        .then(res => {
            dispatch(updateRequests(res));
        })
        .catch(error => {
            console.log(error)
        })
};

export const updateRequest = (dispatch) => (id, data) => {

    const apiService = new APIService();

    apiService.updateRequest(id, data)
        .then(res => {
            dispatch(updateRequests(res));
        })
        .catch(error => {
            console.log(error)
        })
};


export {
    fetchUser,
    fetchDirections,
    updateSettings,
    saveAllData,
    fetchNewOrganization,
    dataOrderUpdate,
    dataItemUpdate,
    errorReset,
    menuUpdate,
    menuVisibiltyUpdate,
    updateSection,
    validationUpdate,
    logOut,
    fetchModerator,
    fetchStats
};
