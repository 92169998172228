import React from 'react';
import {Route, Switch} from "react-router-dom";
import './app.css';
import './shortcuts.css';
import './forms.css';
import './sliding-pane.css';
import Admin from "../admin/admin";
import LoginPageModerator from "../login-page-moderator/login-page-moderator";
import {connect} from "react-redux";
import {logOut, fetchNewOrganization} from "../../actions";
import Landing from "../landing";
import ErrorPanel from "../error-panel/error-panel";
import FeedbackForm from "../feedback/feedback-form";


const App = () => {

    return (

        <div>
            <Switch>
                <Route path='/' exact component={Landing} />
                <Route path='/admin' exact component={Admin} />
                <Route path='/moderator' exact component={LoginPageModerator} />
                <Route path='/feedback' exact component={FeedbackForm} />
            </Switch>

            <ErrorPanel />

        </div>

    );
}


const mapStateToProps = ({ user: { loaded, loading, error, organizations }}) => {
    return { loaded, loading, error, organizations };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: logOut(dispatch),
        fetchNewOrganization: fetchNewOrganization(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
