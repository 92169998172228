import React, {useEffect, useState} from "react";
import {dataItemUpdate, validationUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import { validate } from "../../../utils/validators";
import { getValidationOptions } from "../../../utils/validation-options";

const InputLabeled = props =>  {

    const { submit, onChange, onBlur, label, initValue, textarea, className, min, placeholder } = props;
    const { validationUpdate, form, type, validationOptions, mandatory, initValidation} = props;
    const { dataItemUpdate, data, section, id } = props;
    const { validationErrors } = props.texts;

    const [value, setValue] = useState(initValue || '');
    const [validationText, setValidationText] = useState('');
    const [validationError, setValidationError] = useState(false);

    useEffect(() => {
        if (initValue !== undefined) {
            setValue(initValue);
            validateContent(!!initValidation, initValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initValue]);

    useEffect(() => {

        if (!id) return;

        const dataItem = getItemById(data[section], id);
        if (id && dataItem && dataItem.value !== undefined) {
            setValue(dataItem.value);
            validateContent(!!initValidation, dataItem.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const validateContent = (initError=true, updatedText=value, isBlur=false) => {

        const validationSettings = getValidationOptions(validationOptions)
        const validationError = validate(updatedText, type, {...validationSettings, mandatory: !!mandatory})
        setValidationText (!validationError ? '' : validationErrors[validationError])

        if (initError) {
            setValidationError(validationError !== '');
        }

        validationUpdate({
            form: form || 'all',
            id: id || label,
            value: validationError === ''
        })

        if (onBlur && isBlur) onBlur(updatedText)
    }

    const onChangeHandler = (text) => {

        setValue(text);

        if (!id) {
            onChange(text);
        } else {
            dataItemUpdate({
                id: id,
                value: text,
                action: "update"
            })
        }

        validateContent(false, text)
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if (submit) submit();
        }
    }

    const dataItem =  getItemById(data[section], id);
    const valueToDisplay = !id
        ? value || ''
        : dataItem.value || '';
    const typeToUse = type || 'text';

    const classes = (validationError ? 'validationBorder' : '') + ` ${className || ''}`;

    return (
        <div className="form-group">
            {label &&
                <p className='fs-14'>{label || dataItem.label}
                    {mandatory && <span className='fcred fs-12'>{' *'}</span>}
                </p>
            }
            {!textarea &&
                <input type={typeToUse}
                       min={min}
                       placeholder={placeholder || ''}
                       className={classes}
                       onChange={(e) => onChangeHandler(e.target.value)}
                       onKeyDown={onKeyDown}
                       value={valueToDisplay}
                       onBlur={() => validateContent(true, value, true)} onFocus={() => setValidationError('')}/>
            }
            {textarea &&
                <textarea type='text'
                       className={validationError ? 'validationBorder' : ''}
                       onChange={(e) => onChangeHandler(e.target.value)}
                       value={valueToDisplay}
                       onBlur={() => validateContent(true, value, true)} onFocus={() => setValidationError('')}/>
            }
            {validationError &&
                <div className='validationError'>{validationText}</div>
            }
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }, language: { texts }}) => {
    return { data, section, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch),
        validationUpdate: validationUpdate(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputLabeled);
