import React from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import FormServiceContainer from "./form-service-container";
import {ImageLabeled, InputLabeled} from "../index";
import SaveButton from "../../savebutton/save-button";

const ServiceItem = props => {

    const {data, section, id, texts, dataItemUpdate} = props;
    const {serviceComponent: {
        serviceHeadingText,
        serviceTitleText,
        serviceContentText,
        servicePriceText,
        serviceImageText,
        serviceUrlText,
        serviceEmailText,
    }} = texts.components

    const onChangeHandler = (type, value) => {
        const dataItem =  getItemById(data[section], id);
        dataItemUpdate({
            id: id,
            value: {
                ...dataItem.value,
                [type]: value
            },
            action: "update"
        })
    }

    const dataItem =  getItemById(data[section], id);
    const services = dataItem.children;
    const { headingValue, textValue, imageValue, linkValue, priceValue, subscribe, comment } = dataItem.value;

    return (
        <div className='mt-10'>
            <div className='mb-15'>
                <h4>{headingValue || serviceHeadingText}</h4>
            </div>
            <div className="form-group">
                <InputLabeled label={serviceTitleText} mandatory initValidation initValue={headingValue}
                              onChange={(value) => onChangeHandler('headingValue', value)}/>
            </div>
            <div>
                <InputLabeled mandatory initValidation textarea label={serviceContentText}
                              onChange={(value) => onChangeHandler('textValue', value)} initValue={textValue}/>
            </div>
            <div>
                <InputLabeled className='w-150' type='number' min={0} initValidation label={servicePriceText}
                              onChange={(value) => onChangeHandler('priceValue', value)} initValue={priceValue}/>
            </div>
            <div>
                <ImageLabeled heading={serviceImageText} initValue={imageValue}
                              onChange={(value) => onChangeHandler('imageValue', value)} local/>
            </div>
            <div>
                <InputLabeled type="url" label={serviceUrlText} initValue={linkValue}
                              onChange={(value) => onChangeHandler('linkValue', value)}/>
            </div>
            <div>
                <InputLabeled type="email" label={serviceEmailText} initValue={subscribe}
                              onChange={(value) => onChangeHandler('subscribe', value)}/>
            </div>
            <div>
                <InputLabeled type="text" placeholder='Сообщите тему вашего обращения' label={'Запрос деталей записи от пользователя'} initValue={subscribe}
                              onChange={(value) => onChangeHandler('comment', value)}/>
            </div>

            <div>
                {services &&

                services.map(
                    (item) => {
                        return (
                            <FormServiceContainer key={item.id} id={item.id} />
                        )
                    }
                )
                }
            </div>
            <div className='separator1px mt-10' />
            <div className='mt-10 d-flex-end'>
                <SaveButton />
            </div>
        </div>
    )

}

const mapStateToProps = ({ user: { data, section }, language: {texts}}) => {
    return { data, section, texts };
};


const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceItem);
