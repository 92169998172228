import React, {useState} from 'react';
import {connect} from "react-redux";
import { fetchNewOrganization, logOut } from "../../actions";
import SelectButton from "../form-controls/common/select-button";
import InfoPanel from "../info-panel/info-panel";


const AdminHeader = props => {

    const { organizations, fetchNewOrganization, texts, data } = props;
    const [ infoPanelVisible, setInfoPanelVisible ] = useState(false);

    const {adminPage: {headingText, subheadingText, exitText, supportText, phonesText}} = texts.screens;

    const changeOrganization = (index, item) => {
        fetchNewOrganization(item.id)
    }

    return (
        <div className="d-flex-wrap-sb">
            <div className='mrweb-20 mbmob-20 mobileBlock'>
                <h3>{headingText}</h3>
                <p className="mt-5 fs-12">{subheadingText}</p>
            </div>
            <div className='mrweb-20 mbmob-20 mobileBlock'>
                <small>
                    <strong>{supportText}</strong><br/>
                    {phonesText[data.city] && phonesText[data.city].map((item, index) =>
                        <span key={index}><a href={`tel:${item}`}>{`${item}`}</a>{`${index < phonesText[data.city].length - 1 ? ' | ' : ''}`}</span>
                    )}
                </small>
            </div>
            <div className='d-flex-end-ac'>
                {(organizations.length > 1) &&
                <div className='mr-10'>
                    <SelectButton style={{width: 200}} elements={organizations} operations callBackSelectClick={changeOrganization} />
                </div>
                }
                <div className='d-flex-ac'>
                    <button onClick={() => setInfoPanelVisible(true)} className='button-link mr-10 displayWeb'>Инфо</button>
                    <button className="button" onClick={(e) => props.logOut()}>{exitText}</button>
                </div>
            </div>
            <InfoPanel initVisible={infoPanelVisible} callback={() => setInfoPanelVisible(false)} />
        </div>
    );
}

const mapStateToProps = ({ user: { organizations, data }, language: { texts }}) => {
    return { organizations, data, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: logOut(dispatch),
        fetchNewOrganization: fetchNewOrganization(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
