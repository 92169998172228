import React, {Component} from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyAoHM_q2q-uO_DZ-YOl3B-1mVBdhHyV57c");
Geocode.setLanguage("ru");
Geocode.setRegion("by");
Geocode.enableDebug();

const FormGoogleMap = withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={16}
        defaultOptions={{disableDefaultUI: true, draggable:false}}
        center={{lat: props.geo.lat, lng: props.geo.lng}}
    >
        <Marker position={{lat: props.geo.lat, lng: props.geo.lng}} />
    </GoogleMap>)

class FormMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            address: "",
            lat: -34.397,
            lng: 150.644
        }
    }

    updateGeo = (address) => {
        Geocode.fromAddress(address).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                this.props.onLatLng(Number(lat), Number(lng))
                this.setState({address: address, lat: Number(lat), lng:Number(lng)})
            },
            error => {
                console.error(error);
            }
        );
    }

    render () {

        if (this.props.address !== this.state.address) {
            this.updateGeo(this.props.address);
        }

        return (
            <FormGoogleMap geo={this.state}
                           loadingElement={<div style={{height: `100%`}}/>}
                           containerElement={<div style={{width: '290px', height: '150px'}}/>}
                           mapElement={<div style={{height: `100%`}}/>}/>
        )
    }

}


export default FormMap
