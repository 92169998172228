import React from 'react';

import phone from "../../../assets/landing/mc-phone.png"
import qrapple from "../../../assets/landing/mc-qr-apple.png"
import qrandroid from "../../../assets/landing/mc-qr-android.png"

const Citizens = () => {

    return (
        <div id="citizens" className="landing-gray-box mt-50">
            <div className="landing-container">
                <div className="d-flex-sb wrapMobile">
                    <div className="landing-gray-box-image wp-100">
                        <img src={phone} width={260} alt="phone" />
                    </div>

                    <div className="mlweb-30 wp-100 p-30">
                        <div>
                            <h1 className="mt-50">Для Горожан</h1>
                            <p className="mt-30">Мобильное приложение «Мой Город» подволяет горожанам получить простой
                             и удобный доступ к государственным и частным организациям из одного приложения</p>
                            <p className="mt-30">В пилотном проекте для Полоцка участвует более 160 различных организаций,
                             предоставляющих информацию, новости, услуги и сервисы</p>
                            <p className="mt-30">Благодаря этому взаимодействие с городом становится легким и приятным -
                             каждый человек может сам оценить удобство работы с городскими сервисами через свой смартфон: </p>
                        </div>
                        <div className="d-flex mt-30">
                            <div>
                                <a href="https://apps.apple.com/us/app/%D0%BC%D0%BE%D0%B9-%D0%B3%D0%BE%D1%80%D0%BE%D0%B4-%D0%BF%D0%BE%D0%BB%D0%BE%D1%86%D0%BA/id1542353498" target="_blank" rel="noopener noreferrer"><img width={120} src={qrapple} alt="qr-apple" /></a>
                            </div>
                            <div className="ml-30">
                                <a href="https://play.google.com/store/apps/details?id=com.beltelecom.mycity" target="_blank" rel="noopener noreferrer"><img width={120} src={qrandroid} alt="qr-android" /></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );

}


export default Citizens
